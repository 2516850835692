<template>
<div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
        <!-- Header main -->
        <HeaderMain></HeaderMain>
    </header>
    <!-- login section -->
    <LoginSection class="main-content"></LoginSection>
    <!-- Blog  -->
    <Footer classname="bg-cus on-dark"></Footer>
</div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import HeaderMain from "@/components/n1/HeaderMain"
import Footer from "@/pages/n1/Footer"
import LoginSection from "@/components/n1/LoginSection"

export default {
  name: 'Login',
  components:{
    HeaderMain,
    Footer,
    LoginSection
  },
  data () {
    return {
      SectionData,

    }
  }
}
</script>

<style scoped>
.main-content {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
}
.bg-cus {
  background-color: #ffe5d0;
}
</style>
